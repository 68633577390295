import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import i18n from '@/i18n'
import '@/sass/variables.scss'
import '@/sass/overrides.sass'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas, fab, far) // Include needed icons

Vue.use(Vuetify)

const theme = {
  primary: '#1E88E5',
  secondary: '#78909C',
  accent: '#9C27b0',
  info: '#00CAE3',
  success: '#4CAF50'
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi' || 'fas' || 'fab' || 'far'
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})

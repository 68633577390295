import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//guard router
function guardMyRoute(to, from, next)
{
  const publicPages = ['/auth', '/authregister', '/home',''];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  
  //jika membuka halaman yang dilindungi sedangkan blm login maka akan dibawa ke halaman LOGIN
  if(authRequired && !loggedIn)  {
      next('/auth');
  } else {
    next();
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      beforeEnter : guardMyRoute,
      children: [
        {
          name: 'Home',
          beforeEnter : guardMyRoute,
          path: '/home',
          component: () => import('@/views/dashboard/Home'),
        },
        {
          name: 'AuthUser',
          path: '/auth',
          component: () => import('@/views/dashboard/Login'),
        },
        {
          name: 'Dashboard',
          beforeEnter : guardMyRoute,
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'User',
          beforeEnter : guardMyRoute,
          path: '/pages/userprofile',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        // Data Induk
        {
          name: 'Area jual',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-areajual',
          component: () => import('@/views/dashboard/pages/datainduk-areajual'),
        },  
        {
          name: 'Rekening Akuntansi',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-rekening',
          component: () => import('@/views/dashboard/pages/datainduk-rekening'),
        },  
        {
          name: 'Manajemen User',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-user',
          component: () => import('@/views/dashboard/pages/datainduk-user'),
        },
        // Arus Kas Induk
        {
          name: 'Kas Masuk - Mandiri',
          beforeEnter : guardMyRoute,
          path: 'pages/aruskasinduk-masuk',
          component: () => import('@/views/dashboard/pages/aruskasinduk-masuk'),
        },  
        {
          name: 'Kas Keluar - Mandiri',
          beforeEnter : guardMyRoute,
          path: 'pages/aruskasinduk-keluar',
          component: () => import('@/views/dashboard/pages/aruskasinduk-keluar'),
        },  
        // Arus Kas
        {
          name: 'Arus Kas',
          beforeEnter : guardMyRoute,
          path: 'pages/aruskas',
          component: () => import('@/views/dashboard/pages/aruskas'),
        },  
        {
          name: 'Kas Masuk',
          beforeEnter : guardMyRoute,
          path: 'pages/aruskas-masuk',
          component: () => import('@/views/dashboard/pages/aruskas-masuk'),
        },  
        {
          name: 'Kas Keluar',
          beforeEnter : guardMyRoute,
          path: 'pages/aruskas-keluar',
          component: () => import('@/views/dashboard/pages/aruskas-keluar'),
        },  
        {
          name: 'Pengajuan Lembur',
          beforeEnter : guardMyRoute,
          path: 'pages/lembur-pengajuan',
          component: () => import('@/views/dashboard/pages/lembur-pengajuan'),
        }, 
        {
          name: 'Riwayat Lembur',
          beforeEnter : guardMyRoute,
          path: 'pages/lembur-riwayat',
          component: () => import('@/views/dashboard/pages/lembur-riwayat'),
        },  
        // Laporan
        {
          name: 'Laporan Arus Kas - Mandiri',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-aruskasinduk',
          component: () => import('@/views/dashboard/pages/laporan-aruskasinduk'),
        },
        {
          name: 'Laporan Arus Kas',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-aruskas',
          component: () => import('@/views/dashboard/pages/laporan-aruskas'),
        },
        {
          name: 'Laporan Lembur',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-lembur',
          component: () => import('@/views/dashboard/pages/laporan-lembur'),
        },
        //Invoice
        // {
        //   name: 'Data Invoice',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/invoice-data',
        //   component: () => import('@/views/dashboard/pages/invoice-data'),
        // },
        // {
        //   name: 'Invoice Baru',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/invoice-add',
        //   component: () => import('@/views/dashboard/pages/invoice-add'),
        // },
        // {
        //   name: 'Invoice Baru2',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/invoice-addv2',
        //   component: () => import('@/views/dashboard/pages/invoice-addv2'),
        // },
        // PAJAK
        // {
        //   name: 'Pajak',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/pajak',
        //   component: () => import('@/views/dashboard/pages/pajak'),
        // },
        // LAPORAN
        // {
        //   name: 'Laporan Perusahaan',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-dataperusahaan',
        //   component: () => import('@/views/dashboard/pages/laporan-dataperusahaan'),
        // },
        // {
        //   name: 'Laporan Invoice',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-invoice',
        //   component: () => import('@/views/dashboard/pages/laporan-invoice'),
        // },
        // {
        //   name: 'Laporan Invoice Per Customer',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-invoicepercustomer',
        //   component: () => import('@/views/dashboard/pages/laporan-invoicepercustomer'),
        // },
        // {
        //   name: 'Laporan Invoice Per Operator',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-invoiceperoperator',
        //   component: () => import('@/views/dashboard/pages/laporan-invoiceperoperator'),
        // },
        // {
        //   name: 'Laporan Invoice Per Area Jual',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-invoiceperareajual',
        //   component: () => import('@/views/dashboard/pages/laporan-invoiceperareajual'),
        // },
        // {
        //   name: 'Laporan Invoice Per Jenis Tindakan',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-invoiceperjenistindakan',
        //   component: () => import('@/views/dashboard/pages/laporan-invoiceperjenistindakan'),
        // },
        // {
        //   name: 'Laporan Kwitansi',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-kwitansi',
        //   component: () => import('@/views/dashboard/pages/laporan-kwitansi'),
        // },
        // {
        //   name: 'Laporan Kwitansi kelompok',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-kwitansi-kelompok',
        //   component: () => import('@/views/dashboard/pages/laporan-kwitansi-kelompok'),
        // },
        // {
        //   name: 'Laporan Surat Jalan',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-suratjalan',
        //   component: () => import('@/views/dashboard/pages/laporan-suratjalan'),
        // },
        // {
        //   name: 'Laporan Unit Penjualan',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-unitpenjualan',
        //   component: () => import('@/views/dashboard/pages/laporan-unitpenjualan'),
        // },
        // {
        //   name: 'Laporan Hpp & Margin',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-hppmargin',
        //   component: () => import('@/views/dashboard/pages/laporan-hppmargin'),
        // },
        // {
        //   name: 'Laporan Sharing Profit',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-sharingprofit',
        //   component: () => import('@/views/dashboard/pages/laporan-sharingprofit'),
        // },
        // {
        //   name: 'Laporan Status Invoice',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-statusinvoice',
        //   component: () => import('@/views/dashboard/pages/laporan-statusinvoice'),
        // },
        // {
        //   name: 'User Profile',
        //   path: 'pages/userprofile',
        //   beforeEnter : guardMyRoute,
        //   component: () => import('@/views/dashboard/pages/UserProfile'),
        // },

        // {
        //   name: 'Notifications',
        //   path: 'components/notifications',
        //   component: () => import('@/views/dashboard/component/Notifications'),
        // },
        // {
        //   name: 'Icons',
        //   path: 'components/icons',
        //   component: () => import('@/views/dashboard/component/Icons'),
        // },
        // {
        //   name: 'Typography',
        //   path: 'components/typography-item',
        //   component: () => import('@/views/dashboard/component/TypographyItem'),
        // },
        // // Tables
        // {
        //   name: 'Regular Tables',
        //   path: 'tables/regular-tables',
        //   component: () => import('@/views/dashboard/tables/RegularTables'),
        // },
        // // Maps
        // {
        //   name: 'Google Maps',
        //   path: 'maps/google-maps',
        //   component: () => import('@/views/dashboard/maps/GoogleMaps'),
        // },
        // // Upgrade
        // {
        //   name: 'Upgrade',
        //   path: 'upgrade',
        //   component: () => import('@/views/dashboard/Upgrade'),
        // },
      ],
    },
  ],
})
